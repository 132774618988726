
const CorporateHome = ({CorporateData}) => {
    return (

        <main  className="bg-cover bg-center  w-full      "
        style={{ backgroundImage: 'url(/image/corporateBg.png)' }} 
      >
      <div className=" w-full sx:pt-6  mt-14 sx:px-3 md:px-10 md:pt-16 pb-8 pr-4 ">
        <img
          src={CorporateData.imgSrc}
          alt="Corporate Logo"
          className="w-80  " 
        />
        <h1 className="text-2xl tracking-widest pt-2  text-white font-semibold">{CorporateData.service}</h1>
        <div className="flex flex-col gap-y-3 mt-10 ">
          {CorporateData.texts.map((text, index) => (
            <p key={index} className="text-white font-medium sm:text-base md:text-base">
              {text}
            </p>
          ))}
        </div>
        <div>
            <div className="mt-6">
        <a
          href="#demo"
          className="bg-FinvisageText text-white py-2 px-4 rounded  hover:bg-blue-600 transition duration-300"
        >
           Request for demo
        </a>
        </div>
        </div>
        

      </div>
      </main>
    );
  };
  
  export default CorporateHome;