import ContactForm from "../../components/DemoFormSection";
import FinvisageVideo from "../../components/FinvisageVideo";
import CorporateFaq from "./CorporateFaq";
import CorporateHome from "./CorporateHome";

const Corporates = ({data}) => {
    return (
      <div>
      
       <CorporateHome CorporateData={data.subSection[0]}/>
       <CorporateFaq title={data.midSection[0].title} BanksQNA={data.midSection[0].QA} />
       <FinvisageVideo title={data.corporates.title} videoId={data.corporates.videoLink}/>

       <ContactForm/>
             </div>
    );
  };
  
  export default Corporates;