import React, { useEffect, useState } from "react";
import { testimonialsData } from "../config/Constant";

const Testimonials = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonialsData.length);
    }, 6000);

    return () => clearInterval(interval);
  }, []);

  const nextTestimonial = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonialsData.length);
  };

  
  const prevTestimonial = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? testimonialsData.length - 1 : prevIndex - 1
    );
  };


  const testimonialsToShow = testimonialsData.slice(
    currentIndex,
    currentIndex + 1
  ); 

  return (
    <section className="relative">
      <div
        className="bg-cover bg-center md:h-fit flex items-center justify-center overflow-hidden"
        style={{ backgroundImage: "url(/image/testinomialBg.png)" }}
      >
        <div className="flex flex-col items-center py-6 px-3 w-full max-w-6xl">
          <h1 className="text-white text-3xl py-8 text-center">Testimonials</h1>
          <div className="relative w-full flex flex-col justify-center">
            <div className="flex justify-center gap-6 sx:h-[600px] md:h-[440px] w-full flex-wrap">
              {testimonialsToShow.map((testimonial, index) => (
                <div
                  key={index}
                  className="text-white md:tracking-wide flex flex-col w-full max-w-md"
                >
                  <p className="text-justify ">{`"${testimonial.quote}"`}</p>
                  <h2 className="text-left text-lg  mt-4">
                    {testimonial.name}
                    <br />
                    {testimonial.position}, <br />
                    {testimonial.company}
                  </h2>
                </div>
              ))}
            </div>

            <div className="flex gap-x-10 justify-center">
              <button
                onClick={prevTestimonial}
                className=" text-white text-2xl"
              >
                <i className="fa fa-chevron-left"></i>
              </button>

              <button
                onClick={nextTestimonial}
                className=" text-white text-2xl"
              >
                <i className="fa fa-chevron-right"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <hr className="border-gray-400" />
    </section>
  );
};

export default Testimonials;
