import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import AOS from 'aos';
// import 'aos/dist/aos.css'; // Import AOS styles
import {
  CorporateHomePageData,
  ForexConsultantsHomePageData,
  BanksHomePageData,
} from "../../config/Constant";

const HomeHero = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fade, setFade] = useState(false);
  const navigate = useNavigate();

  const dataArray = [
    CorporateHomePageData,
    ForexConsultantsHomePageData,
    BanksHomePageData,
  ];
  const routes = ["/corporates", "/forex-consultants", "/banks"];

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(true);
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % dataArray.length);
        setFade(false);
      }, 500);
    }, 6000);

    return () => clearInterval(interval);
  }, [dataArray.length]);

  const handleIndicatorClick = (index) => {
    setFade(true);
    setTimeout(() => {
      setCurrentIndex(index);
      setFade(false);
    }, 500);
  };

  const handleClickMore = () => {
    navigate(routes[currentIndex]);
  };

  const currentData = dataArray[currentIndex];

  return (
    <main
      className="relative bg-cover bg-center sx:h-screen md:h-[600px] w-full"
      style={{ backgroundImage: "url(/image/homePageBg.png)" }}
    >
      <div className="px-3 pt-8   lg:px-10 h-1/3">
        <div
          className={`flex mt-14 flex-col md:flex-row gap-6 transition-opacity duration-500 ${
            fade ? "opacity-0" : "opacity-100"
          }`}
        >
          <div className="flex-1" data-aos="fade-left">
            <img
              src={currentData.imgSrc}
              alt={`${currentData.service} Logo`}
              className="w-80 max-w-xs md:max-w-md lg:max-w-lg xl:max-w-xl"
            />
            <h1 className="text-2xl tracking-widest pt-2 text-white font-semibold text-left">
              {currentData.service}
            </h1>
            <div className="flex flex-col gap-y-3 mt-4">
              {currentData.texts.map((text, index) => (
                <p
                  key={index}
                  className="text-white font-medium sm:text-base md:text-lg text-left"
                >
                  {text}
                </p>
              ))}
            </div>
            <button
              onClick={handleClickMore}
              className="bg-FinvisageText text-white py-2 px-4 rounded my-6 mx-auto lg:mx-0 hover:bg-blue-600 transition duration-300"
            >
              Click here to know more
            </button>
          </div>
          <div className="flex-1  " data-aos="fade-right">
            <img
              src={currentData.imgSrc2}
              alt="Carousel"
              className="w-full max-w-xs md:max-w-md lg:max-w-lg xl:max-w-2xl md:mx-auto"
            />
          </div>
        </div>
      </div>

      <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2 flex justify-center space-x-2">
        {dataArray.map((_, index) => (
          <button
            key={index}
            onClick={() => handleIndicatorClick(index)}
            className={`w-3 h-3 rounded-full ${
              currentIndex === index ? "bg-blue-600" : "bg-gray-300"
            }`}
          />
        ))}
      </div>
    </main>
  );
};

export default HomeHero;
