

const CorporateFaq = ({BanksQNA,title}) => {
  return (
    <>
      {/* <main className="w-full mx-auto px-10 pt-10 bg-custom-radial-gradient   ">
        <h1 className="text-PricingText text-3xl font-bold text-center">
        {title}
        </h1>
     
        <div className="pt-6 ">
          {BanksQNA.map((item, index) => (
            <div key={index} className="flex gap-20  py-8 px-4">
              {index % 2 === 0 ? (
                <>
                  <div className="flex-1">
                    <h3 className="text-PricingText text-xl font-bold">{item.question}</h3>
                    <p className="mt-6 text-PricingText">{item.answer}</p>
   
                    {item.subHeading&& <p className="mt-6 text-PricingText">{item.subHeading}</p>}
                  </div>
                  <div className="flex-1 flex justify-center">
                    <img
                      src={item.imgSrc}
                      alt={item.question}
                      className="w-80 h-48 "
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="flex-1 flex justify-center">
                    <img
                      src={item.imgSrc}
                      alt={item.question}
                      className="w-80 h-48 "
                    />
                  </div>
                  <div className="flex-1">
                    <h3 className=" text-PricingText text-xl font-bold">{item.question}</h3>
                    <p className="mt-6 text-PricingText">{item.answer}</p>
                    {item.subHeading&& <p className="mt-6 text-PricingText">{item.subHeading}</p>}
                   
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      </main> */}
   <main className="w-full md:mx-auto px-3 md:px-10 pt-10 bg-custom-radial-gradient">
  <h1 className="text-PricingText text-3xl font-bold text-center">{title}</h1>

  <div className="pt-6">
    {BanksQNA.map((item, index) => (
      <div key={index} className="py-8 ">
        <div
          className={`flex gap-6 flex-col sm:flex-row items-center ${
            index % 2 === 0 ? 'sm:flex-row-reverse' : 'sm:flex-row'
          }`}
        >
        
          <div className="flex-1 flex justify-center">
            <img
              src={item.imgSrc}
              alt={item.question}
              className="w-full sm:w-80 h-auto max-w-xs"
            />
          </div>

          
          <div className="flex-1 mt-4 sm:mt-0">
            <h3 className="text-PricingText text-xl font-bold">{item.question}</h3>
            <p className="mt-6 text-PricingText">{item.answer}</p>
            {item.subHeading && <p className="mt-6 text-PricingText">{item.subHeading}</p>}
          </div>
        </div>
      </div>
    ))}
  </div>
</main>


    </>
  );
};
export default CorporateFaq;
