

import DemoFormSection from "../../components/DemoFormSection";
import FinvisageSection from "../../components/FinvisageSection";
import FinvisageVideo from "../../components/FinvisageVideo";
import Testimonials from "../../components/Testimonials";
import HomeHero from "./HomeHero";


const Home = ({staticData}) => {
    return (
      <div>
       <HomeHero/>
        <FinvisageSection/>
        <FinvisageVideo/>
        <DemoFormSection/>
        <Testimonials/>
      </div>
    );
  };
  
  export default Home;