import React from "react";
import { Link } from "react-router-dom";
import { footerItems } from "../config/Constant";

const Footer = () => {
  return (
    <footer className="bg-FooterBg sx:px-3  md:px-10 py-9 font-inter text-FooterText">
      <div className="flex flex-col gap-y-12">
        <div className="  lg:flex justify-between items-start">
          <div className=" sm:w-full lg:w-5/12">
            <Link to="/">
              <img
                src="/image/transparentLogo.png"
                alt="Logo"
                className=" sx:w-56 md:w-80"
              />
            </Link>
          </div>

          <div className=" sx:pt-4 flex flex-grow space-x-6">
            <div className="flex-1">
              <h4 className="font-bold mb-4 ">Solutions</h4>
              <ul>
                {footerItems.column1.map((item, index) => (
                  <li key={index} className="sx:mb-3 mb-1">
                    <Link
                      to={item.path}
                      className="hover:underline font-normal "
                    >
                      {item.label}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="flex-1">
              <h4 className="font-bold mb-4">Contact Us</h4>
              <ul>
                {footerItems.column2.map((item, index) => (
                  <li key={index} className="sx:mb-3 mb-1">
                    {item.url ? (
                      <a
                        href={item.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:underline font-normal "
                      >
                        {item.label}
                      </a>
                    ) : (
                      <Link
                        to={item.path}
                        className="hover:underline font-normal"
                      >
                        {item.label}
                      </Link>
                    )}
                  </li>
                ))}
              </ul>
              <a
                href="#demo"
                className="hover:underline font-normal "
              >
                Request a demo
              </a>
            </div>
            <div className="flex-1 ">
              <h4 className="font-bold mb-4 ">Links</h4>
              <ul>
                {footerItems.column3.map((item, index) => (
                  <li key={index} className="sx:mb-3  mb-1">
                    {item.url ? (
                      <a
                        href={item.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:underline   font-normal "
                      >
                        {item.label}
                      </a>
                    ) : (
                      <Link
                        to={item.path}
                        className="hover:underline  font-normal "
                      >
                        {item.label}
                      </Link>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="">
          <h1 className="text-center text-sm">
            Copyright © 2024 Finvisage All Rights Reserved
          </h1>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
