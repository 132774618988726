import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from '../pages/Home/Home';
import Corporates from '../pages/Corporates/Corporates';
import ForexConsultants from '../pages/ForexConsultants/ForexConsultants';
import Banks from '../pages/Banks/Bank';
// import Nbfcs from '../pages/Nbfcs.js/Nbfcs';
import { staticData } from '../config/constant-data';

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/corporates" element={<Corporates data={staticData}/>} />
      <Route path="/forex-consultants" element={<ForexConsultants data={staticData} />} />
      <Route path="/banks" element={<Banks data={staticData}/>} />
      {/* <Route path="/nbfcs" element={<Nbfcs />} /> */}
    </Routes>
  );
};

export default AppRoutes;