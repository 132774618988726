import ContactForm from "../../components/DemoFormSection";
import Testimonials from "../../components/Testimonials";
import CorporateFaq from "../Corporates/CorporateFaq";
import CorporateHome from "../Corporates/CorporateHome";

const Banks = ({data}) => {
    return (
      <div>
          <CorporateHome CorporateData={data.subSection[2]}/>
       <CorporateFaq title={data.midSection[2].title} BanksQNA={data.midSection[2].QA} />
      
       <Testimonials/>
       <ContactForm hide={true}/>
      </div>
    );
  };
  
  export default Banks;