import ContactForm from "../../components/DemoFormSection";
import FinvisageVideo from "../../components/FinvisageVideo";
import CorporateFaq from "../Corporates/CorporateFaq";
import CorporateHome from "../Corporates/CorporateHome";

const ForexConsultants = ({data}) => {
    return (
      <div>
     <CorporateHome CorporateData={data.subSection[1]}/>
       <CorporateFaq title={data.midSection[1].title} BanksQNA={data.midSection[1].QA} />
      
       <FinvisageVideo title={data.fx.title} videoId={data.fx.videoLink}/>
       <ContactForm hide={true}/>
      </div>
    );
  };
  
  export default ForexConsultants;