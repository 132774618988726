import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import StartPricing from './StartPricing';
import { db} from '../firebase.config';
import {ref,set} from 'firebase/database'
const ContactForm = ({hide=false}) => {


  const [formData, setFormData] = useState({
    name: '',
    email: '',
    country: '',
    phone: '',
    organization: '',
    description: '',
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const validateForm = () => {
    const newErrors = {};
    const { name, email, country, phone, organization,  } = formData;

    if (!name) newErrors.name = 'Name is required';
    if (!email) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = 'Email is invalid';
    }
    if (!country) newErrors.country = 'Country is required';
    if (!phone) {
      newErrors.phone = 'Phone number is required';
    } else if (!/^\+?\d{6,18}$/.test(phone)) {
      newErrors.phone = 'Phone number is invalid';
    }
    if (!organization) newErrors.organization = 'Organization name is required';
    // if (!description) newErrors.description = 'Description is required';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; 
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (validateForm()) {
     
      const id=Date.now()
     
      const useRef=ref(db,'user/' + id);
      set(useRef,{
        Name:formData.name,
        Email:formData.email,
        Country:formData.country,
        Phone:formData.phone,
        Organization:formData.organization,
        description:formData.description,
      })
      .then(() => {
        toast.success('Thank you for reaching out to us! We will get back to you very soon.', {
          position: 'top-right',
        });
      })
      .catch((error) => {
        console.error('Error writing to Firebase:', error);
        toast.error('Something went wrong. Please try again later.');
      });
      setFormData({
        name: '',
        email: '',
        country: '',
        phone: '',
        organization: '',
        description: '',
      });
    }
  };

  return (
    <>
      <ToastContainer />
         <main id="demo"  className=' md:flex w-full bg-custom-radial-gradient'>
     {!hide && <div className='md:w-1/2   '>
        <StartPricing/>
        
      </div>}
     
      <form onSubmit={handleSubmit} className=" md:w-1/2 md:mx-auto sx:px-3 md:px-12 py-8  ">
          <div className='pb-8'>     
        <h2 className="text-4xl font-bold text-center text-PricingText my-4">Request a Demo</h2>
          <p className='text-Graycolor text-center '>Please fill in all details</p>
         
          </div>
          <div className=' '>
        <div className="mb-4">
          <label className="block text-Graycolor text-lg font-semibold mb-2">Name <span className="text-red-500">*</span></label>
          <input
            type="text"
            name="name"
            placeholder='Example: John Doe'
            value={formData.name}
            onChange={handleChange}
            className="w-full border-b bg-transparent border-gray-300 focus:outline-none focus:border-blue-500 placeholder:italic placeholder:text-base"
            required
          />
          {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}
        </div>

      
        <div className="mb-4">
          <label className="block text-Graycolor text-lg font-semibold  mb-2">Email Address<span className="text-red-500">*</span></label>
          <input
            type="email"
            name="email"
            value={formData.email}
             placeholder='Please enter a valid email address'
            onChange={handleChange}
            className="w-full border-b  bg-transparent border-gray-300 focus:outline-none focus:border-blue-500   placeholder:italic placeholder:text-base"
            required
          />
          {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
        </div>

    
        <div className="mb-4">
          <label className="block text-Graycolor text-lg font-semibold mb-2">Country <span className="text-red-500">*</span></label>
          <input
            type="text"
            name="country"
            value={formData.country}
             placeholder='Example: India'
            onChange={handleChange}
            className="w-full border-b  bg-transparent border-gray-300 focus:outline-none focus:border-blue-500  placeholder:italic placeholder:text-base"
            required
          />
          {errors.country && <p className="text-red-500 text-sm">{errors.country}</p>}
        </div>

     
        <div className="mb-4">
          <label className="block text-Graycolor text-lg font-semibold mb-2">Phone Number</label>
          <input
            type="text"
            name="phone"
            value={formData.phone}
             placeholder='Kindly include your country code with the number'
            onChange={handleChange}
            className="w-full border-b  bg-transparent border-gray-300 focus:outline-none focus:border-blue-500   placeholder:italic placeholder:text-base"
         
          />
          {errors.phone && <p className="text-red-500 text-sm">{errors.phone}</p>}
        </div>

     
        <div className="mb-4">
          <label className="block text-Graycolor text-lg font-semibold mb-2">Organization Name <span className="text-red-500">*</span></label>
          <input
            type="text"
            name="organization"
            value={formData.organization}
             placeholder='Example: ABC Ltd'
            onChange={handleChange}
            className="w-full border-b  bg-transparent border-gray-300 focus:outline-none focus:border-blue-500   placeholder:italic placeholder:text-base"
            required
          />
          {errors.organization && <p className="text-red-500 text-sm">{errors.organization}</p>}
        </div>

        <div className="mb-4">
          <label className="block text-Graycolor text-lg font-semibold mb-4">Description </label>
          <textarea
            name="description"
            value={formData.description}
             placeholder='Explain your requirement in a couple of lines'
            onChange={handleChange}
            rows="4" 
          cols="50" 
          maxLength={200}
          
            className="w-full border resize-none bg-transparent border-gray-300 focus:outline-none focus:border-blue-500   placeholder:italic placeholder:text-base"
            
          ></textarea>
          {/* {errors.description && <p className="text-red-500 text-sm">{errors.description}</p>} */}
        </div>
        </div>
          <div className='flex justify-center  '>
          <button type="submit" className="  text-white text-xl font-medium px-14 py-3 rounded-full bg-FinvisageText hover:bg-blue-600">
          Request a demo
        </button>
        </div>
      </form>
      </main>
    </>
  );
};

export default ContactForm;


// import React, { useState } from 'react';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import StartPricing from './StartPricing';
// import { db } from '../firebase.config';
// import { ref, set } from 'firebase/database';
// import axios from 'axios'; 

// const ContactForm = ({ hide = false }) => {
//   const [formData, setFormData] = useState({
//     name: '',
//     email: '',
//     country: '',
//     phone: '',
//     organization: '',
//     description: '',
//   });

//   const [errors, setErrors] = useState({});

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({ ...prevData, [name]: value }));
//   };

//   const validateForm = () => {
//     const newErrors = {};
//     const { name, email, country, phone, organization, description } = formData;

//     if (!name) newErrors.name = 'Name is required';
//     if (!email) newErrors.email = 'Email is required';
//     else if (!/\S+@\S+\.\S+/.test(email)) newErrors.email = 'Email is invalid';
//     if (!country) newErrors.country = 'Country is required';
//     if (!phone) {
//       newErrors.phone = 'Phone number is required';
//     } else if (!/^\+?\d{6,18}$/.test(phone)) {
//       newErrors.phone = 'Phone number is invalid';
//     }
//     if (!organization) newErrors.organization = 'Organization name is required';

//     setErrors(newErrors);
//     return Object.keys(newErrors).length === 0; 
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     if (validateForm()) {
//       try {
      
//         const id = Date.now(); 
//         const userRef = ref(db, 'user/' + id);
//         await set(userRef, formData);

        
//         await axios.post('https://your-firebase-function-url/send-email', formData);

        
//         toast.success('Thank you for reaching out to us! We will get back to you soon.', { position: 'top-right' });

       
//         setFormData({
//           name: '',
//           email: '',
//           country: '',
//           phone: '',
//           organization: '',
//           description: '',
//         });
//       } catch (error) {
//         console.error('Error submitting form: ', error);
//         toast.error('Something went wrong. Please try again later.');
//       }
//     }
//   };

//   return (
//     <>
//       <ToastContainer />
//       <main id="demo" className="flex w-full bg-custom-radial-gradient">
//         {!hide && (
//           <div className="w-1/2">
//             <StartPricing />
//           </div>
//         )}

//         <form onSubmit={handleSubmit} className="w-1/2 mx-auto px-12 py-8">
//           <div className="pb-8">
//             <h2 className="text-4xl font-bold text-center text-PricingText my-4">Request a Demo</h2>
//             <p className="text-Graycolor text-center">Please fill in all details</p>
//           </div>

//           <div className="mb-4">
//             <label className="block text-Graycolor text-lg font-semibold mb-2">
//               Name <span className="text-red-500">*</span>
//             </label>
//             <input
//               type="text"
//               name="name"
//               placeholder="Example: John Doe"
//               value={formData.name}
//               onChange={handleChange}
//               className="w-full border-b bg-transparent border-gray-300 focus:outline-none focus:border-blue-500 placeholder:italic placeholder:text-base"
//               required
//             />
//             {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}
//           </div>

//           <div className="mb-4">
//             <label className="block text-Graycolor text-lg font-semibold mb-2">
//               Email Address <span className="text-red-500">*</span>
//             </label>
//             <input
//               type="email"
//               name="email"
//               placeholder="Please enter a valid email address"
//               value={formData.email}
//               onChange={handleChange}
//               className="w-full border-b bg-transparent border-gray-300 focus:outline-none focus:border-blue-500 placeholder:italic placeholder:text-base"
//               required
//             />
//             {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
//           </div>

//           <div className="mb-4">
//             <label className="block text-Graycolor text-lg font-semibold mb-2">
//               Country <span className="text-red-500">*</span>
//             </label>
//             <input
//               type="text"
//               name="country"
//               placeholder="Example: India"
//               value={formData.country}
//               onChange={handleChange}
//               className="w-full border-b bg-transparent border-gray-300 focus:outline-none focus:border-blue-500 placeholder:italic placeholder:text-base"
//               required
//             />
//             {errors.country && <p className="text-red-500 text-sm">{errors.country}</p>}
//           </div>

//           <div className="mb-4">
//             <label className="block text-Graycolor text-lg font-semibold mb-2">Phone Number</label>
//             <input
//               type="text"
//               name="phone"
//               placeholder="Kindly include your country code with the number"
//               value={formData.phone}
//               onChange={handleChange}
//               className="w-full border-b bg-transparent border-gray-300 focus:outline-none focus:border-blue-500 placeholder:italic placeholder:text-base"
//             />
//             {errors.phone && <p className="text-red-500 text-sm">{errors.phone}</p>}
//           </div>

//           <div className="mb-4">
//             <label className="block text-Graycolor text-lg font-semibold mb-2">
//               Organization Name <span className="text-red-500">*</span>
//             </label>
//             <input
//               type="text"
//               name="organization"
//               placeholder="Example: ABC Ltd"
//               value={formData.organization}
//               onChange={handleChange}
//               className="w-full border-b bg-transparent border-gray-300 focus:outline-none focus:border-blue-500 placeholder:italic placeholder:text-base"
//               required
//             />
//             {errors.organization && <p className="text-red-500 text-sm">{errors.organization}</p>}
//           </div>

//           <div className="mb-4">
//             <label className="block text-Graycolor text-lg font-semibold mb-4">Description</label>
//             <textarea
//               name="description"
//               value={formData.description}
//               placeholder="Explain your requirement in a couple of lines"
//               onChange={handleChange}
//               rows="4"
//               maxLength={200}
//               className="w-full border resize-none bg-transparent border-gray-300 focus:outline-none focus:border-blue-500 placeholder:italic placeholder:text-base"
//             />
//           </div>

//           <div className="flex justify-center">
//             <button
//               type="submit"
//               className="text-white text-xl font-medium px-14 py-3 rounded-full bg-FinvisageText hover:bg-blue-600"
//             >
//               Request a demo
//             </button>
//           </div>
//         </form>
//       </main>
//     </>
//   );
// };

// export default ContactForm;
